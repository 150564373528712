// Translated
// Migrated
<template>
  <b-modal
    ref="charterBookingErrorModal"
    v-model="show"
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="noCloseOnEsc"
    body-class="no-padding"
    centered
    size="lg"
  >
    <div class="alert mb-0">
      <div class="flex items-center">
        <h3 class="mb-0">
          <slot name="icon">
            <fa
              class="mr-4"
              icon="info-circle"
            />
          </slot>
        </h3>
        <div class="grow">
          <slot name="text">
            <span data-i18n="serverError">
              {{ $t('serverError') }}
            </span>
          </slot>
        </div>
        <button
          class="btn btn-blue ml-4"
          @click="clear"
        >
          <slot name="btnText">
            <span data-i18n="ok">
              {{ $t('ok') }}
            </span>
          </slot>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default defineNuxtComponent({
  props: {
    errors: {
      required: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      validator: v => true,
    },

    noCloseOnEsc: {
      type: Boolean,
      default: true,
    },

    closeSelf: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['clear'],

  data () {
    return {
      show: false,
    }
  },

  watch: {
    errors (v) {
      this.show = !!v
    },
  },

  mounted () {
    this.show = !!this.errors
  },

  methods: {
    clear () {
      this.$emit('clear')

      if (this.closeSelf) {
        this.show = false
      }
    },
  },
})
</script>
